<template>
    <div>
        <div>Data Extraction</div>
        <div style="display: flex; flex-direction:row; border: 0px solid black; justify-content: space-between;">
          <div>
            <kendo-datasource
                :ref="'studyMappingSelectorDataSource'"
                :data="selector">
            </kendo-datasource>
            <kendo-datasource
                :ref="'filterSelectorDataSource'"
                :data="filterStudySelector">
            </kendo-datasource>
            <kendo-datasource
                :ref="'sortSelectorDataSource'"
                :data="sortStudySelector">
            </kendo-datasource>
            Grouped by:
            <kendo-dropdownlist
              :id="'studyMappingSelector'"
              :data-source-ref="'studyMappingSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'"
              :change="changeSelector">
            </kendo-dropdownlist>
            Filter by:
            <kendo-dropdownlist
              :id="'filterSelector'"
              :data-source-ref="'filterSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'">
            </kendo-dropdownlist>
            Sort By:
            <kendo-dropdownlist
              :id="'sortSelector'"
              :data-source-ref="'sortSelectorDataSource'"
              :data-value-field="'SelectorIdentity'"
              :data-text-field="'SelectorName'">
            </kendo-dropdownlist>
            <div v-if="visible1" style="justify-content: flex-start; display: flex; flex-direction: row;">
                <div class="flagForDe" style="width: 20px; margin: 5px;">&nbsp;</div>
                <div style="margin: 5px;">Flagged for Data Extraction</div>
                <div class="excludedCitation" style="width: 20px; margin: 5px;">&nbsp;</div>
                <div style="margin: 5px;">Excluded Citations</div>
            </div>
          </div>
          <div v-if="visible0" style="justify-content: flex-end; display: flex; flex-direction: row;">
             <kendo-datasource
                :ref="'citationsFilterSelectorDataSource'"
                :data="stageSelector">
            </kendo-datasource>
            <div style="margin: 5px; display: flex; flex-direction: row;">
              <div>Citations Filter By:</div>
              <kendo-dropdownlist
                :id="'citationsFilterSelector'"
                :data-source-ref="'citationsFilterSelectorDataSource'"
                :data-value-field="'SelectorIdentity'"
                :data-text-field="'SelectorName'">
              </kendo-dropdownlist>
            </div>
          </div>
          <div v-if="visible0" style="justify-content: flex-end; display: flex; flex-direction: row;">
            <kendo-datasource
                :ref="'subsequentCitationsSortSelectorDataSource'"
                :data="subsequentSelector">
            </kendo-datasource>
            <div style="margin: 5px; display: flex; flex-direction: row;">
              <div>Subsequent Citations Sort By:</div>
              <kendo-dropdownlist
                :id="'subsequentCitationsSortSelector'"
                :data-source-ref="'subsequentCitationsSortSelectorDataSource'"
                :data-value-field="'SelectorIdentity'"
                :data-text-field="'SelectorName'">
              </kendo-dropdownlist>
            </div>
            <div class="flagForDe" style="width: 20px; margin: 5px;">&nbsp;</div>
            <div style="margin: 5px;">Flagged for Data Extraction</div>
            <div class="excludedCitation" style="width: 20px; margin: 5px;">&nbsp;</div>
            <div style="margin: 5px;">Excluded Citations</div>
          </div>
          <div v-if="visible1" style="justify-content: flex-end; display: flex; flex-direction: row;">
            <kendo-datasource
                :ref="'studiesSortSelectorDataSource'"
                :data="studiesSortSelector">
            </kendo-datasource>
            <div style="margin: 0px; display: flex; flex-direction: row; height: 20px;">
              <div>Studies Sort By:</div>
              <kendo-dropdownlist
                :id="'studiesSortSelector'"
                :data-source-ref="'studiesSortSelectorDataSource'"
                :data-value-field="'SelectorIdentity'"
                :data-text-field="'SelectorName'">
              </kendo-dropdownlist>
            </div>
          </div>
        </div>
        <div v-if="visible0">
          <kendo-grid id="study-mapping-by-studies-grid-b"
            :ref="'studyListGrid'"
            style="study-list-grid"
            :data-source="studyMappingByStudiesDataSource"
            :columns="columnsStudies"
            :selectable="false"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable">
          </kendo-grid>
        </div>
        <div v-if="visible1">
          <kendo-grid id="study-mapping-by-citations-grid"
            :ref="'citationListGrid'"
            style="citation-list-grid"
            :data-source="studyMappingByCitationsDataSource"
            :columns="columnsCitations"
            :selectable="selectable"
            :sortable="sortable"
            :pageable="pageable"
            :groupable="groupable"
            :excel="excel"
            :scrollable="scrollable"
            :filterable="filterable"
            :change="modifyStudy">
          </kendo-grid>
        </div>
        <b-button-group class="action-buttons-nav">
            <b-button type="submit" variant="primary" size="sm" style="width: 80px" v-if="!visible2" @click="addNewStudy()">New Study</b-button>
            &nbsp;
            <b-button type="submit" variant="primary" size="sm" style="width: 150px">Print/Export to Excel</b-button>
        </b-button-group>
        <kendo-window :ref="'detachWindow'"
                      :width="windowWidth"
                      :title="windowTitle"
                      :visible="windowVisible"
                      :modal="true"
                      :top="'0px'">
            <component class="component" :is="component"></component>
        </kendo-window>
    </div>
</template>

<script>

import StudyMappingByStudiesDataSource from '@/assets/data/StudyMappingByStudies.json'
import StudyMappingByCitationsDataSource from '@/assets/data/StudyMappingByCitations.json'
import SelectCitation from '@/views/secure/manage/templates/SelectCitationBV1.vue'
import SelectStudy from '@/views/secure/manage/templates/SelectStudyV2.vue'
import AddNewStudy from '@/views/secure/manage/templates/AddNewStudyV2.vue'
import mitt from 'mitt'
const emitter = mitt()

export default {
  name: 'validation-home',
  components: {
    SelectCitation,
    SelectStudy,
    AddNewStudy
  },
  data () {
    return {
      windowTitle: '',
      windowWidth: '',
      windowVisible: false,
      component: '',
      visible0: true,
      visible1: false,
      visible2: false,
      excel: { fileName: 'Kendo UI Grid Export.xlsx', allPages: true },
      selector: [
        { SelectorIdentity: 2, SelectorName: 'Studies' },
        { SelectorIdentity: 3, SelectorName: 'Citations' }
      ],
      filterStudySelector: [
        { SelectorIdentity: 1, SelectorName: 'With at least one study' },
        { SelectorIdentity: 2, SelectorName: 'Without studies' },
        { SelectorIdentity: 3, SelectorName: 'Is Main Citation' },
        { SelectorIdentity: 4, SelectorName: 'Is not Main Citation' },
        { SelectorIdentity: 5, SelectorName: 'With at least one excluded citation' }
      ],
      filterCitationSelector: [
        { SelectorIdentity: 1, SelectorName: 'With at least one study' },
        { SelectorIdentity: 2, SelectorName: 'Without studies' },
        { SelectorIdentity: 3, SelectorName: 'Is main' },
        { SelectorIdentity: 4, SelectorName: 'Is not main' }
      ],
      sortStudySelector: [
        { SelectorIdentity: 1, SelectorName: 'High to low number of citations per study' },
        { SelectorIdentity: 2, SelectorName: 'Low to high number of citations per study' },
        { SelectorIdentity: 3, SelectorName: 'Study ID' },
        { SelectorIdentity: 4, SelectorName: 'Study Acronym' }
      ],
      sortCitationSelector: [
        { SelectorIdentity: 1, SelectorName: 'High to low number of studies per citation' },
        { SelectorIdentity: 2, SelectorName: 'Low to high number of studies per citation' },
        { SelectorIdentity: 3, SelectorName: 'Citation ID' },
        { SelectorIdentity: 4, SelectorName: 'Author' },
        { SelectorIdentity: 5, SelectorName: 'Year' },
        { SelectorIdentity: 6, SelectorName: 'Title' },
        { SelectorIdentity: 7, SelectorName: 'Current Stage' }
      ],
      stageSelector: [
        { SelectorIdentity: 1, SelectorName: 'Any Stage' },
        { SelectorIdentity: 2, SelectorName: 'Any Except Excluded' },
        { SelectorIdentity: 3, SelectorName: 'Excluded Only' },
        { SelectorIdentity: 4, SelectorName: 'Flagged for Data Extraction' },
        { SelectorIdentity: 5, SelectorName: 'Abstract Screening' },
        { SelectorIdentity: 6, SelectorName: 'Full Text Screening' },
        { SelectorIdentity: 7, SelectorName: 'Full Data Extraction' }
      ],
      subsequentSelector: [
        { SelectorIdentity: 1, SelectorName: 'Citation ID - High to low' },
        { SelectorIdentity: 2, SelectorName: 'Citation ID - Low to high' },
        { SelectorIdentity: 3, SelectorName: 'Year - High to low' },
        { SelectorIdentity: 4, SelectorName: 'Year - Low to high' },
        { SelectorIdentity: 5, SelectorName: 'Author - High to low' },
        { SelectorIdentity: 6, SelectorName: 'Author - Low to high' },
        { SelectorIdentity: 7, SelectorName: 'Title - High to low' },
        { SelectorIdentity: 8, SelectorName: 'Title - Low to high' }
      ],
      studiesSortSelector: [
        { SelectorIdentity: 1, SelectorName: 'Is Main - High to low' },
        { SelectorIdentity: 2, SelectorName: 'Is Main - Low to high' },
        { SelectorIdentity: 3, SelectorName: 'Study ID - High to low' },
        { SelectorIdentity: 4, SelectorName: 'Study ID - Low to high' },
        { SelectorIdentity: 5, SelectorName: 'Study Acronym - High to low' },
        { SelectorIdentity: 6, SelectorName: 'Study Acronym - Low to high' }
      ],
      columnsStudies: [
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'StudyID', title: 'Study ID', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true },
            { field: 'StudyAcronym', title: 'Study Acronym', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true }
          ]
        },
        {
          title: 'Extract<br>Data',
          command: [{
            name: 'Extract',
            click: this.goToDE
          }],
          width: '30px',
          headerAttributes: { class: 'grid-header-light' }
        },
        {
          field: 'MainPub',
          title: 'Main Citation',
          width: '75px',
          headerAttributes: { class: 'grid-header-light' },
          attributes: { class: '# if(MainPub.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
          template: '#=MainPub.replace("--Ex--", "")#',
          filterable: true
        },
        {
          title: 'Subsequent Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            {
              field: 'SubsequentPub01',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub01.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub01.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub02',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub02.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub02.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub03',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub03.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub03.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub04',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if (SubsequentPub04.includes("--Fde--")) { # flagForDe # } else if (SubsequentPub04.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub04.replace("--Ex--", "").replace("--Fde--", "")#',
              filterable: false
            }
          ]
        }
      ],
      columnsStudiesB: [
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'StudyID', title: 'ID', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true },
            { field: 'StudyAcronym', title: 'Study Acronym', width: '50px', headerAttributes: { class: 'grid-header-dark' }, filterable: true }
          ]
        },
        {
          title: 'Map<br>Citation(s)5',
          command: [{
            name: 'Edit',
            click: this.addNewCitationB
          }],
          width: '30px',
          headerAttributes: { class: 'grid-header-light' }
        },
        {
          field: 'MainPub',
          title: 'Main Citation',
          width: '75px',
          headerAttributes: { class: 'grid-header-light' },
          attributes: { class: '# if(MainPub.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
          template: '#=MainPub.replace("--Ex--", "")#',
          filterable: true
        },
        {
          title: 'Subsequent Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            {
              field: 'SubsequentPub01',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub01.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub01.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub02',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub02.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub02.replace("--Ex--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub03',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub03.includes("--Ex--")) { # excludedCitation # } else { # defaultCitation # } #' },
              template: '#=SubsequentPub03.replace("--Ex--", "").replace("--Fde--", "")#',
              filterable: false
            },
            {
              field: 'SubsequentPub04',
              title: 'Citation',
              width: '75px',
              headerAttributes: { class: 'grid-header-light' },
              attributes: { class: '# if(SubsequentPub04.includes("--Fde--")) { # flagForDe # }  else { # defaultCitation # } #' },
              template: '#=SubsequentPub04.replace("--Fde--", "")#',
              filterable: false
            }
          ]
        }
      ],
      columnsCitations: [
        {
          title: 'Citations',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'CitationID', title: 'Citation ID', width: '50px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Author', title: 'Author', width: '60px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Year', title: 'Year', width: '60px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'Title', title: 'Title', width: '80px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } },
            { field: 'CurrentStage', title: 'Current Stage', width: '80px', headerAttributes: { class: 'grid-header-light' }, filterable: true, attributes: { class: '# if(CitationID.includes("1")) { # flagForDe # } else if (CitationID.includes("3")) { # excludedCitation # } else { # defaultCitation # } #' } }
          ]
        },
        {
          title: 'Map<br>Study(s)',
          command: [{
            name: 'Edit',
            click: this.linkStudy
          }],
          width: '40px',
          headerAttributes: { class: 'grid-header-dark' }
        },
        {
          title: 'Studies',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'SubsequentStudy01', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy02', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy03', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false },
            { field: 'SubsequentStudy04', title: 'Study', width: '75px', headerAttributes: { class: 'grid-header-dark' }, filterable: false }
          ]
        }
      ],
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      groupable: false,
      selectable: 'cell',
      sortable: true,
      scrollable: true,
      filterable: true,
      studyMappingByStudiesDataSource: StudyMappingByStudiesDataSource,
      studyMappingByCitationsDataSource: StudyMappingByCitationsDataSource
    }
  },
  methods: {
    change: function () {
      alert()
    },
    changeSelector: function (e) {
      var dsFilterWidget = this.$refs.filterSelectorDataSource.kendoWidget()
      var dsSortWidget = this.$refs.sortSelectorDataSource.kendoWidget()
      switch (e.sender.selectedIndex) {
        case 0:
          dsFilterWidget.data(this.filterStudySelector)
          dsSortWidget.data(this.sortStudySelector)
          this.visible0 = true
          this.visible1 = false
          break
        case 1:
          dsFilterWidget.data(this.filterCitationSelector)
          dsSortWidget.data(this.sortCitationSelector)
          this.visible0 = false
          this.visible1 = true
          break
      }
    },
    reportAction: function (action) {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      switch (action) {
        case 'SelectCitation':
          if (this.$store.state.citationChange === 1) {
            this.windowTitle = 'Add Citation(s)'
          } else {
            this.windowTitle = 'Edit mapped Citations'
          }
          this.component = 'SelectCitation'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 5200
          windowWidget.center().open()
          break
        case 'SelectCitationB':
          this.windowTitle = 'Edit Citations for this study'
          this.windowWidth = '1200px'
          this.component = 'SelectCitationB'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 5200
          windowWidget.center().open()
          break
        case 'AddNewStudy':
          this.windowTitle = 'New Study'
          this.component = 'AddNewStudy'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 800
          windowWidget.center().open()
          this.windowWidth = '800px'

          break
        case 'SelectStudy':
          this.windowTitle = 'Edit mapped Studies'
          this.component = 'SelectStudy'
          this.windowVisible = true
          windowWidget.close()
          windowWidget.width = 800
          windowWidget.center().open()
          break
      }
    },
    addNewCitation: function () {
      this.$store.state.citationChange = 2
      this.reportAction('SelectCitation')
    },
    addNewCitationB: function () {
      this.$store.state.citationChange = 1
      this.reportAction('SelectCitationB')
    },
    modifyCitation: function (e) {
      console.log(e)
      console.log(this)
      var gridWidget = this.$refs.studyListGrid.kendoWidget()
      this.$store.state.isMainCitationSelected = (gridWidget.select().closest('td').index() === 3)
      this.$store.state.citationChange = 2
      this.reportAction('SelectCitation')
    },
    addNewStudy: function () {
      alert(7)
      this.reportAction('AddNewStudy')
    },
    goToDE: function () {
      // alert('Warning: this option will allow you to edit the citations from the indiviual review section to perform data extraction')
      this.$router.push('/Secure/ReviewDE')
    },
    linkStudy: function () {
      this.reportAction('SelectStudy')
    }
  },
  created: function () {
    emitter.on('changeStudyWindowTitle', (title) => {
      this.windowTitle = title
    })
  }
}
</script>

<style>
  .excludedCitation {
    background-color: rgb(253, 162, 180);
    color: black;
  }

  .flagForDe {
    background-color: rgb(161, 230, 159);
    color: black;
  }

  .defaultCitation {
    color: black;
  }
  .k-grid .k-grid-content .k-state-selected {
    background-color: rgb(131, 176, 248) !important;
  }
</style>
